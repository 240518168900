.container {
  background: #003a8d;
  color: #fff;
  padding: 0 50px;
}

.container.user {
  background: #fff;
  color: #0B0A3E;
}

.content {
  max-width: 1140px;
  margin: 0 auto;
  padding: 40px 0 20px 0;
}

.light,
h2.light,
h3.light,
.promotext {
  color: #fff;
}

.promotext {
  font-size: 22px;
}

.title {
  font-size: 30px;
  font-weight: 700;
}

.top_content {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  button {
    border-radius: 10px;
    border: 2px solid #00D1FF;
    width: 360px;
    height: 40px;
    font-size: 16px;
    font-weight: 700;
    background: #fff;
    cursor: pointer;
  }
}

@media (max-width: 600px) {
  .container {
    padding: 0 10px;
  }

  .content {
    padding: 20px 0 20px 0;
  }


  .title {
    font-size: 18px;
  }

  .top_content {
    margin-top: 10px;

    button {
      width: 100%;
      height: 30px;
      font-size: 14px;
    }
  }
}