.block {
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.4);
  width: 100%;
  height: auto;
  margin-bottom: 20px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  position: relative;

  .left {
    width: 30%;
  }

  .img {
    position: absolute;
    top: 0;
    left: 0;
    width: 30%;
    height: 100%;
    border-radius: 10px 0px 0px 10px;
    background: #3d3d3d;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;

      border-radius: 10px 0px 0px 10px;
    }
  }

  .right {
    padding: 10px 15px 15px 15px;
    width: 70%;
    min-height: auto;
  }
}

@media (max-width: 800px) {
  .block {
    flex-direction: column;

    .left {
      width: 100%;
      height: 250px;
    }

    .img {
      width: 100%;
      height: 250px;
      border-radius: 10px 10px 0px 0px;
    }

    .right {
      width: 100%;
    }
  }
}

@media (max-width: 600px) {
  .block {
    .left {
      height: 200px;
    }

    .img {
      height: 200px;
    }
  }
}
