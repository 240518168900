.block {
   height: 100%;

   .top {
      .name {
         font-size: 25px;
         font-weight: 700;
         cursor: pointer;

         a {
            color: #0B0A3E;
         }
      }

      .name:hover {
         text-decoration: underline;
      }

      .address {
         color: #7D7D7D;
         font-size: 14px;
         font-weight: 400;
      }
   }

   .bottom {
      margin-top: 15px;

      .bottom_title {
         font-size: 16px;
         font-weight: 700;
         margin-bottom: 10px;
      }

      ul {
         position: relative;
         list-style: none;
         padding: 0;
         margin: 0;
         display: flex;
         justify-content: space-around;
         align-items: center;

         li {
            position: relative;
            z-index: 2;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;

            .circle {
               width: 30px;
               height: 30px;
               border-radius: 50%;
               background: #B8B8B8;
               display: flex;
               justify-content: center;
               align-items: center;

               .circle_content {
                  width: 15px;
                  height: 15px;
                  border-radius: 50%;
                  background: #fff;
               }
            }

            .circle.one {
               background: #F1D900;
            }

            .circle.two {
               background: #0A9E4C;
            }

            .li_text {
               font-size: 12px;
               font-weight: 400;
               margin-top: 3px;
            }
         }
      }

      ul::before {
         z-index: 1;
         content: '';
         position: absolute;
         top: 15px;
         left: 0;
         width: 100%;
         height: 1px;
         background-color: #B8B8B8;
      }

      .info {
         padding: 10px;
         position: relative;
         margin-top: 15px;
         height: auto;
         font-size: 14px;
         font-weight: 400;
         width: 100%;
         border-radius: 5px;
         background: #FFF;
         box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.40);
         display: flex;
         justify-content: flex-start;
         align-items: center;
      }

      .info.one::before {
         background: #F1D900;
      }

      .info.two::before {
         background: #0A9E4C;
      }

      .info::before {
         content: '';
         position: absolute;
         top: 0;
         left: 0;
         width: 100%;
         height: 5px;
         border-radius: 5px;
         background: #F1D900;
      }
   }
}

@media (max-width: 950px) {
   .block {
      .bottom {
         ul {
            li {
               .circle {
                  width: 20px;
                  height: 20px;

                  .circle_content {
                     width: 10px;
                     height: 10px;
                  }
               }

               .li_text {
                  font-size: 10px;
                  margin-top: 2px;
               }
            }
         }

         ul::before {
            top: 10px;
         }

      }
   }
}

@media (max-width: 600px) {
   .block {
      .top {
         .name {
            font-size: 16px;
            text-decoration: underline;
         }

         .address {
            font-size: 12px;
         }
      }

      .bottom {
         margin-top: 10px;

         .bottom_title {
            font-size: 12px;
         }

         ul {

            li {

               .circle {
                  width: 16px;
                  height: 16px;

                  .circle_content {
                     width: 8px;
                     height: 8px;
                  }
               }

               .li_text {
                  font-size: 10px;
                  text-align: center;
                  margin-top: 2px;
               }
            }
         }

         ul::before {
            top: 8px;
         }

         .info {
            margin-top: 10px;
            font-size: 12px;
         }
      }
   }
}