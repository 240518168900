.block {
   .title {
      font-size: 25px;
      font-weight: 700;
   }

   .progress {
      margin-top: 20px;
      width: 100%;
      height: 20px;
      border-radius: 10px;
      background: #F3F3F3;
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25) inset;

      .progress_content {
         background-color: #0A9E4C;
      }
   }

   .progress_text {
      font-size: 14px;
      font-weight: 400;
   }

   button {
      margin-top: 20px;
      border-radius: 10px;
      background: #00D1FF;
      width: 250px;
      height: 40px;
      border: none;
      font-size: 16px;
      font-weight: 700;
      cursor: pointer;
   }
}

@media (max-width: 600px) {
   .block {
      .title {
         font-size: 14px;
      }

      .progress {
         margin-top: 10px;
         height: 10px;
      }

      .progress_text {
         font-size: 12px;
      }

      button {
         width: 100%;
         height: 30px;
         font-size: 14px;
      }
   }
}