.block {
   max-width: 1240px;
   background: #fff;
   margin: 20px auto;
   padding: 0 50px;

   .title {
      margin-bottom: 15px;
      font-size: 25px;
      font-weight: 700;
   }

   .none {
      font-size: 16px;
      font-weight: 500;
   }
}

@media (max-width: 600px) {
   .block {
      padding: 0 10px;

      .title {
         margin-bottom: 10px;
         font-size: 16px;
      }

      .none {
         font-size: 14px;
      }
   }
}